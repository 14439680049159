<template>
  <li class="navigation-first-level is-search" :class="{ 'is-active': isOpen }">
    <form
      class="navigation-first-level-search"
      :class="{ 'is-active': isOpen }"
      method="get"
      :action="searchPage"
      @submit.prevent="submit"
    >
      <div class="icon">
        <SpriteSymbol name="search" class="size-20" />
      </div>
      <input
        ref="input"
        v-model="searchTerm"
        type="text"
        class="navigation-first-level-button w-full"
        name="searchQuery"
        autocomplete="off"
        :placeholder="$texts('search', 'Search')"
        @click="$emit('open')"
      />
      <input
        v-if="searchTerm"
        type="submit"
        :value="$texts('submitSearch', 'Suchen')"
        class="sr-only"
      />
      <button
        v-if="searchTerm"
        type="button"
        tabindex="-1"
        :title="$texts('clearSearchTerm', 'Clear search term input')"
        class="delete"
        @click.prevent="clickDelete"
      />
    </form>
    <Transition name="first-level">
      <div
        v-show="showSuggestions"
        class="navigation-first-level-content is-suggestions"
      >
        <div
          ref="suggestionsElement"
          class="navigation-search-suggestions"
          :class="{ 'is-inactive': searchTerm !== suggestionsTerm }"
        >
          <nuxt-link
            v-for="(suggestion, index) in suggestions"
            :key="index"
            :data-index="index"
            :to="getSearchLink(suggestion)"
            @keydown.tab.prevent="onKeydownTab(index, $event)"
          >
            {{ suggestion }}
          </nuxt-link>
        </div>
      </div>
    </Transition>
  </li>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'
import { modulo } from '~/helpers'

const language = useCurrentLanguage()
const { dataLayerPush } = useAnalytics()

const props = defineProps<{
  isOpen: boolean
}>()

defineEmits(['toggle', 'close', 'open'])

const input = ref<HTMLInputElement | null>(null)
const suggestionsElement = ref<HTMLDivElement | null>(null)
const searchTerm = ref('')
const suggestions = ref<string[]>([])
const suggestionsTerm = ref('')
let searchTimeout: number | null = null

const showSuggestions = computed(() => {
  return props.isOpen && suggestions.value.length > 0 && searchTerm.value
})

const searchPage = computed(() => {
  return `/${language.value}/search`
})

watch(searchTerm, function (term) {
  if (searchTimeout !== null) {
    clearTimeout(searchTimeout)
  }
  if (!term) {
    return
  }
  searchTimeout = window.setTimeout(() => {
    loadSuggestions(term)
  }, 100)
})

const router = useRouter()

function focusInput() {
  if (!input.value) {
    return
  }
  input.value.focus()
}

function submit() {
  if (searchTerm.value) {
    const link = getSearchLink(searchTerm.value)
    dataLayerPush({
      event: 'site_search',
      search_term: searchTerm.value,
    })
    router.push(link)
  }
}
function clickDelete() {
  focusInput()
  searchTerm.value = ''
}

function loadSuggestions(term: string) {
  useGraphqlQuery(
    'searchSuggestions',
    { text: term },
    {
      graphqlCaching: {
        client: true,
      },
    },
  ).then((data) => {
    if (searchTerm.value === term) {
      suggestions.value = data.data.suggestions || []
      suggestionsTerm.value = term
    }
  })
}

function getSearchLink(text: string): RouteLocationRaw {
  return {
    name: 'search',
    query: {
      text,
    },
  }
}

function onKeydownTab(index: number, e: KeyboardEvent) {
  if (!suggestionsElement.value) {
    return
  }
  const elements = [...suggestionsElement.value.querySelectorAll('a')]
  const total = elements.length
  if (e.shiftKey) {
    elements[modulo(index - 1, total)]?.focus()
  } else {
    elements[modulo(index + 1, total)]?.focus()
  }
}
</script>

<style lang="postcss">
.navigation-first-level.is-search {
  position: relative;
}
.navigation-first-level-search {
  position: relative;
  z-index: 10;

  @screen md {
    height: 100%;
    width: 7rem;
    transition: 0.5s;
    border-left: 1px solid transparent;
    background: transparent;
    border-radius: 0;
    html[lang='fr'] & {
      width: 8.5rem;
    }
    &:not(.is-active) {
      input {
        cursor: pointer;
      }
    }
    &.is-active {
      @apply border-l-gray-300 text-body;
      width: 20rem !important;
      input::placeholder {
        @apply text-gray-400;
      }
      button {
        opacity: 1;
      }
    }
  }
  @media (max-width: theme('screens.md')) {
    &.is-active svg {
      background: white;
      @apply text-blue-800;
    }
  }
  > .icon {
    width: 1.75rem;
    height: 1.75rem;
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: none;
    padding: 0.25rem;
    font-size: 1.25rem;
    @media (max-width: theme('screens.md')) {
      font-size: 1.5rem;
      border-radius: 100%;
      color: white;
    }
    @screen md {
      width: 1.25rem;
      height: 1.25rem;
      padding: 0;
      right: auto;
      left: 0.75rem;
    }
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;
    transition: 0.5s;
    opacity: 0;
  }

  input.navigation-first-level-button {
    appearance: none;
    border: 0;
    outline: 0;
    box-shadow: none;
    width: 100%;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    color: inherit;
    font-weight: inherit;
    background: transparent;
    @apply font-condensed;
    @media (max-width: theme('screens.md')) {
      color: white;
      &:focus {
        @apply bg-blue-800;
        &::placeholder {
          color: rgba(white, 0.5);
        }
      }
    }
    @screen md {
      padding-left: 2.25rem !important;
      &:focus {
        font-weight: bold;
      }
    }

    &::placeholder {
      color: currentColor;
      transition: 0.4s;
    }
  }
}

.navigation-first-level-content.is-suggestions {
  @screen md {
    transform: none;
    left: auto;
    right: -1px;
    width: calc(20rem + 1px);
  }
  a {
    display: block;
    padding: 0.75rem 1.5rem;
    @apply text-sm;
    color: white;
    @screen md {
      padding: 0.75rem 1rem;
      @apply text-blue-500;
      &:not(:first-child) {
        @apply border-t border-t-gray-100;
      }
    }
  }
}
.navigation-search-suggestions {
  &.is-inactive {
    pointer-events: none;
    a {
      @apply text-gray-300;
    }
  }
}
</style>
